<template>
    <div style="height: 900px;">
      <el-table stripe style="width: 100%;" height="850" :data="comments">
        <el-table-column
          v-for="(title, index) in tableTitleList"
          :prop="title.prop"
          :label="title.name"
          :key="title.prop"
          :width="index === 3 ? 300 : 150"
          :index="title.index"
        ></el-table-column>
        <el-table-column fixed="right" label="Operation">
          <template #default="scope">
            <div style="display: flex">
              <el-button type="primary" link size="small" @click="handleClick(scope.row)">
                Moderate
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
  
      <el-dialog v-model="manage" title="Manage" width="720">
        <el-space wrap>
          <el-card class="box-card" style="width: 670px">
            <template #header>
              <div class="card-header">
                <span>Article: {{ comment.articleName }}</span>
              </div>
            </template>
            <div>
              <el-descriptions>
                <el-descriptions-item label="User">
                  {{ comment.userName }}
                </el-descriptions-item>
              </el-descriptions>
              <el-descriptions>
                <el-descriptions-item label="Time Added">
                  {{ comment.comment.timeAdded }}
                </el-descriptions-item>
              </el-descriptions>
              <el-descriptions>
                <el-descriptions-item label="Comment">
                  {{ comment.comment.content }}
                </el-descriptions-item>
              </el-descriptions>
              <el-descriptions>
                <el-descriptions-item label="Parent Comment">
                  {{ comment.pcontent }}
                </el-descriptions-item>
              </el-descriptions>
            </div>
            <br />
            <div>
              <el-radio-group v-model="radio1">
                <el-radio label="1" border>Approve</el-radio>
                <el-radio label="-1" border>Disapprove</el-radio>
              </el-radio-group>
            </div>
          </el-card>
        </el-space>
        <div>
          <br />
          Allow this user to submit comments without moderating:
          <br />
          <el-switch
            v-model="value1"
            size="small"
            class="mb-2"
            active-text="Approve"
            inactive-text="Disapprove"
          />
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="manage = false">Cancel</el-button>
            <el-button type="primary" @click="confirm">Confirm</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </template>
  
  
<script >
import { ref } from "vue";
import { get, post } from "../../api/index";
import { formatDateTime } from "@/utils/dateFormatter";
import { ElMessage, ElLoading } from "element-plus";

export default {
  name: "PendingComment",
  setup() {
    const column = ref(2);
    const disable = ref(false);
    const manage = ref(false);
    const radio1 = ref("1");
    const tableTitleList = [
      { prop: "uname", name: "User" },
      { prop: "timeAdded", name: "Time Added" },
      { prop: "content", name: "Comment" },
      { prop: "article", name: "Article" },
    ];

    const comments = ref([]);
    const comment = ref({});
    const value1 = ref(true);
    const uid = ref(-1);
    const uname = ref("");

    if (sessionStorage.getItem("token")) {
      post(`/user/logged`).then((res) => {
        if (res.res == 1) {
          uid.value = res.user.uid;
          uname.value = res.user.uname;
        } else {
          sessionStorage.removeItem("token");
          sessionStorage.removeItem('Authorization');
        }
      });
    }

    function handleClick(row) {
      // Call the API to fetch comment details by ID
      post("/comment/get-comment-id", { commentId: row.id }).then((res) => {
        comment.value = res;
        manage.value = true;
      }).catch(error => {
        console.error("Error fetching comment details:", error);
        ElMessage.error("Failed to load comment details. Please try again.");
      });
    }

    function confirm() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      setTimeout(() => {
        loading.close();

        // Comment approve
        post("/admin/moderating-comment", {
          id: comment.value.comment.id,
          status: Number(radio1.value),
          moderator:uid.value
        });

        // User status approve
        const status = value1.value ? 1 : 0;
        post("/admin/moderating-user-comment-authority", {
          userId: comment.value.comment.userId,
          comment_authority: Number(status),
          moderator:uid.value
        });

        ElMessage({
          type: "success",
          message: "Submitted",
        });
        setTimeout(() => {
          manage.value = false;
          load();
        }, 1000);
      }, 2000);
    }

    function load() {
      post("/admin/pending-comment").then((res) => {
        const { pending, userNames, articleNames } = res;
        comments.value = pending.map((comment, index) => ({
          ...comment,
          uname: userNames[index] || "", // Assign user names
          article: articleNames[index] || "", // Assign article names
          pcontent: comment.parentId !== -1 ? "Loading..." : "none", // Placeholder for parent comment content
          timeAdded: formatDateTime(new Date(comment.timeAdded), "yyyy-MM-dd HH:mm:ss"), // Format time added
        }));

        // Fetch parent comments if needed
        comments.value.forEach((comment, index) => {
          if (comment.parentId !== -1) {
            post("/get-comment-id", { commentId: comment.parentId }).then((res) => {
              comments.value[index].pcontent = res.comment;
            });
          }
        });
      });
    }

    load();
    return {
      column,
      manage,
      tableTitleList,
      radio1,
      disable,
      confirm,
      comments,
      comment,
      handleClick,
      value1,
    };
  },
};

  </script>
  <style>   
  .textarea{
    width: 100%;
    font-size: 15px;
    padding: 20px 20px;
    background-color: #EFF1F5;
    border: none;
    border-radius: 15px;
    min-height: 100px;
    margin-top: 10px;
}</style>